var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-template', [_c('v-layout', {
    attrs: {
      "justify-center": "",
      "align-start": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": "100wv"
    }
  }, [_c('div', {
    staticClass: "sub-visual-container sv1"
  }, [_c('div', {
    staticClass: "sub-visual-wrapper"
  }, [_c('h1', {
    staticClass: "sub-visual--title"
  }, [_vm._v(_vm._s(_vm.siteInfo.projectNameKo) + " 소개")]), _c('p', [_vm._v("개인/기업의 이벤트, 마케팅, 고객 관리의 목적으로 모바일 쿠폰을 언제 어디서나 쉽게 실시간으로 발송할 수 있는 서비스입니다.")])])]), _c('div', {
    staticClass: "sub-container",
    staticStyle: {
      "max-width": "1230px",
      "width": "100%",
      "margin": "0 auto"
    }
  }, [_c('div', {
    staticClass: "sub-section"
  }, [_c('div', {
    staticClass: "sub-section--intro"
  }, [_c('p', {
    staticClass: "sub-section--title"
  }, [_vm._v("한국상품권협회 공식 인증 쇼핑몰")]), _c('p', {
    staticClass: "intro-title"
  }, [_vm._v("안녕하세요 모바일쿠폰 전문 쇼핑몰 "), _c('span', [_vm._v(_vm._s(_vm.siteInfo.projectNameKo))]), _vm._v(" 입니다.")]), _c('p', [_vm._v(" " + _vm._s(_vm.siteInfo.projectNameKo) + "는 한국상품권협회 공식 인증 쇼핑몰로써 사업에 필요한 모바일쿠폰을 안정적으로 판매 공급하는데에 최선을 다하도록 하겠습니다. ")]), _c('div', {
    staticClass: "plusu-work"
  }, [_c('ul', [_c('li', [_c('div', {
    staticClass: "work-thumb"
  }, [_c('div', {
    staticClass: "work-thumb--img",
    staticStyle: {
      "background-image": "url('images/subcontent/work1img.png')"
    }
  }), _c('p', {
    staticClass: "work-thumb--tit"
  }, [_vm._v("한국상품권협회")]), _c('p', {
    staticClass: "work-thumb--txt"
  }, [_vm._v("상품권유통업을 10여년 해온 경험과 노하우를 접목한 협회")])])]), _c('li', [_c('div', {
    staticClass: "work-thumb"
  }, [_c('div', {
    staticClass: "work-thumb--img",
    staticStyle: {
      "background-image": "url('images/subcontent/work2img.png')"
    }
  }), _c('p', {
    staticClass: "work-thumb--tit"
  }, [_vm._v("모바일 쿠폰")]), _c('p', {
    staticClass: "work-thumb--txt"
  }, [_vm._v("모바일 쿠폰, 이벤트 당첨 선물 사은품 등으로 활용")])])]), _c('li', [_c('div', {
    staticClass: "work-thumb"
  }, [_c('div', {
    staticClass: "work-thumb--img",
    staticStyle: {
      "background-image": "url('images/subcontent/work3img.png')"
    }
  }), _c('p', {
    staticClass: "work-thumb--tit"
  }, [_vm._v("안정적인 판매 공급")]), _c('p', {
    staticClass: "work-thumb--txt"
  }, [_vm._v("안정적으로 판매 공급하는데에 최선을 다하고 있습니다")])])])])])]), _c('div', {
    staticClass: "sub-section--items sub-section--business"
  }, [_c('p', {
    staticClass: "sub-section--subtitle"
  }, [_vm._v("비지니스 제휴/제안")]), _c('div', {
    staticClass: "business-work"
  }, [_c('div', {
    staticClass: "business-work--box"
  }, [_c('p', {
    staticClass: "business-box--tit"
  }, [_vm._v("절차")]), _c('img', {
    attrs: {
      "src": "images/subcontent/order-img.png",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "business-work--box"
  }, [_c('p', {
    staticClass: "business-box--tit"
  }, [_vm._v("문의")]), _c('p', {
    staticClass: "business-box--txt"
  }, [_vm._v(" 전화 문의 전 이메일로 제휴에 대한 상세한 내용을 먼저 보내주시기 바랍니다. "), _c('br', {
    staticClass: "pc"
  }), _vm._v(" * 서비스 제휴와 관련된 내용이 아닌 경우 답변이 어려울 수 있습니다. ")]), _c('ul', [_c('li', [_c('img', {
    attrs: {
      "src": "images/subcontent/business-mail.png",
      "alt": ""
    }
  })]), _c('li', [_c('img', {
    attrs: {
      "src": "images/subcontent/business-tel.png",
      "alt": ""
    }
  })])])])])]), _c('div', {
    staticClass: "sub-section--items sub-section--shop"
  }, [_c('p', {
    staticClass: "sub-section--subtitle"
  }, [_vm._v("모바일쿠폰 전문 쇼핑몰")]), _c('div', {
    staticClass: "shop-advantages"
  }, [_c('ul', [_c('li', [_c('div', {
    staticClass: "shop-thumb"
  }, [_c('div', {
    staticClass: "shop-thumb--img",
    staticStyle: {
      "background-image": "url('images/subcontent/mobile-shop1.png')"
    }
  }), _c('p', {
    staticClass: "shop-thumb--tit"
  }, [_vm._v(_vm._s(_vm.siteInfo.projectNameKo) + "의 장점")]), _c('p', {
    staticClass: "shop-thumb--subtit"
  }, [_vm._v("언제나 열려있는 쇼핑몰")]), _c('p', {
    staticClass: "shop-thumb--txt"
  }, [_vm._v(" 365일 24시간 열려있는 쇼핑몰로써 회원가입 없이 간편하게 소액결제로 이용하여 구매가 가능합니다. ")])])]), _c('li', [_c('div', {
    staticClass: "shop-thumb"
  }, [_c('div', {
    staticClass: "shop-thumb--img",
    staticStyle: {
      "background-image": "url('images/subcontent/mobile-shop2.png')"
    }
  }), _c('p', {
    staticClass: "shop-thumb--tit"
  }, [_vm._v(_vm._s(_vm.siteInfo.projectNameKo) + "의 발송 서비스")]), _c('p', {
    staticClass: "shop-thumb--subtit"
  }, [_vm._v("보안된 실시간 모바일 핀번호")]), _c('p', {
    staticClass: "shop-thumb--txt"
  }, [_vm._v(" 한층 보완된 실시간 모바일 핀번호 발송 서비스 기다리는 시간없이 구매 후 사용이 가능하도록 자동 발송 해드리겠습니다. ")])])])])])])])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }